import './App.scss';
import { Routes, Route, Navigate } from 'react-router-dom'
import { Suspense, lazy } from 'react';

import CrearCuenta from './components/CrearCuenta';
import CambiarContraseña from './components/CambiarContraseña';
import Login from './components/Login';
import Loading from './components/Loading';
// import Perfiles from './components/Perfiles';
import Invitacion from './components/Invitacion';
import SeleccionarCuenta from './components/SeleccionarCuenta';
import CategoriasProductos from './components/CategoriasProductos';
import Bitacora from './components/Bitacora';
import ResumenProductos from './components/ResumenProductos';
import Resumen from './components/Resumen';
// import NuevoLayout from './components/NuevoLayout';

// import Principal from './components/Principal';
// import Automatizaciones from './components/Automatizaciones';
// import Cuentas from './components/Cuentas';
// import Ajustes from './components/Ajustes';
// import Categorias from './components/Categorias';
// import CrearPerfil from './components/CrearPerfil';

const Ajustes = lazy(() => import('./components/Ajustes')); 
const Categorias = lazy(() => import('./components/Categorias')); 
const CrearPerfil = lazy(() => import('./components/CrearPerfil')); 
const Cuentas = lazy(() => import('./components/Cuentas')); 
const Automatizaciones = lazy(() => import('./components/Automatizaciones')); 
const Principal = lazy(() => import('./components/Principal')); 


function App() {

  return (
    <div className={window.localStorage.getItem("light") ? "website_light":"website"}>
      <Routes>
        <Route index element={<Login />} />
        <Route path="/api/profiles/share/add/:invite" element={<Invitacion />} />
        <Route path="/login" element={<Login />} />
        <Route path="/cambiar-contra" element={<CambiarContraseña />} />
        <Route path="/signup" element={<CrearCuenta />} />
        <Route path="/crear-perfil" element={<Suspense fallback={<Loading />}><CrearPerfil /></Suspense>} />
        <Route path="/seleccion-cuentas" element={<Suspense fallback={<Loading />}><SeleccionarCuenta /></Suspense>} />
        
        <Route path="/home" element={<Suspense fallback={<Loading />}><Principal /></Suspense>} />
        <Route path="/categorias" element={<Suspense fallback={<Loading />}><Categorias /></Suspense>} />
        <Route path="/automatizaciones" element={<Suspense fallback={<Loading />}><Automatizaciones /></Suspense>} />
        <Route path="/cuentas" element={<Suspense fallback={<Loading />}><Cuentas /></Suspense>} />
        {/* <Route path="/perfiles" element={<Suspense fallback={<Loading />}><Perfiles /></Suspense>} /> */}
        <Route path="/ajustes" element={<Suspense fallback={<Loading />}><Ajustes /></Suspense>} />
        <Route path="/bitacora" element={<Suspense fallback={<Loading />}><Bitacora /></Suspense>} />
        <Route path="/productos" element={<Suspense fallback={<Loading />}><CategoriasProductos /></Suspense>} />
        <Route path="/resumen" element={<Suspense fallback={<Loading />}><Resumen /></Suspense>} />
        <Route path="/resumen-prod" element={<Suspense fallback={<Loading />}><ResumenProductos /></Suspense>} />
        {/* <Route path="/layout" element={<Suspense fallback={<Loading />}><NuevoLayout /></Suspense>} /> */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </div>
  );
}

export default App;

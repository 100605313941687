// import React, { useEffect, useState } from 'react'
import edit_row from "../assets/edit_row.svg"
import cross_icon from "../assets/cross_icon.svg"
import check_pend from "../assets/check_pend.png"
import comentarios from "../assets/comentarios.png"
import "../styles/Categorias.scss"
import edit_icon from "../assets/edit_icon.svg"
// import { getCategories } from "../services/getCategories"
// import { getWallets } from "../services/getWallets"

function TablaTransProd({gastos,setShowPassword,setMontoTrans,showModify, setShowModify, setModifyId, walletName,productos,editable, idProduct, pendiente, walletId,transacName, withCategory, showDate,setShowDate, setComments, profile_name, transactions, date, currAmount, comments, clave, idCategory, identificador, setApiUrl, showConfirmation, setShowConfirmation, setCategoryId, showComments, setShowComments}) {
    // const [/*category*/, setCategory] = useState([{name:""}])
    // const [walletName, setWalletName] = useState([{name:""}])
    // const [prodName, setProdName] = useState([{name:""}])
    // const [productos, setProductos] = useState([]);
    // const [loader, setLoader] = useState(false)

  //   useEffect(() => {
  //     if(window.localStorage.getItem("walletId")){
  //       const TOKEN = window.localStorage.getItem("loggedToken")
  //       const WALLET_ID = window.localStorage.getItem("walletId")
  //       const url = `${process.env.REACT_APP_BACKEND_URL}/api/product/wal/${WALLET_ID}/`;
  //       const headers = {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Token ${JSON.parse(TOKEN)}`
  //       };
    
  //       fetch(url,{ headers, withCredentials:true })
  //       .then(response => response.json())
  //       .then(data => {setProductos(data.filter(prod => prod.id === idProduct))})
  //     } else {
  //         getCategories()
  //         .then(data => {setProductos(data.filter(prod => prod.id === idProduct))})    
  //   }
  // // eslint-disable-next-line
  //   },[]);


//     useEffect(() => {
//       getWallets()
//       .then(data => CombineCategories(data.filter(wall => wall.id === walletId)))
// // eslint-disable-next-line
//     },[])

//     async function CombineCategories(local){

//       // if(local.length === 0){
//       //   setCompartido(true)
//       // }

//       const TOKEN = window.localStorage.getItem("loggedToken");
//       const url = `${process.env.REACT_APP_BACKEND_URL}/api/share/`;
//       const headers = {
//         "Content-Type": "application/json",
//         Authorization: `Token ${JSON.parse(TOKEN)}`,
//       };
  
//       fetch(url, { headers, withCredentials: true })
//         .then((response) => response.json())
//         .then((data) => setWalletName(data.concat(local).filter(wall => wall.id === walletId)));

//   }


    // useEffect(() => {
    //     // setLoader(true)
    //     if(withCategory){
    //     const TOKEN = window.localStorage.getItem("loggedToken")
    //     const PROFILE_ID = window.localStorage.getItem("profileId")
    //     const url = `${process.env.REACT_APP_BACKEND_URL}/api/categories/used/${PROFILE_ID}/`;
    //     const headers = {
    //       'Content-Type': 'application/json',
    //       'Authorization': `Token ${JSON.parse(TOKEN)}`
    //     };
    
    //     fetch(url,{ headers, withCredentials:true })
    //     .then(response => response.json())
    //     .then(data => setCategory(data.filter(cat => cat.id === idCategory)) || "")
    //     // .finally(console.log(category))
    //     } else {
    //       const TOKEN = window.localStorage.getItem("loggedToken")
    //       const url = `${process.env.REACT_APP_BACKEND_URL}/api/share/`;
    //       const headers = {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Token ${JSON.parse(TOKEN)}`
    //       };
      
    //       fetch(url,{ headers, withCredentials:true })
    //       .then(response => response.json())
    //       .then(data => compartidasTrans(data) || "")
    //     }
    //   // eslint-disable-next-line
    //   },[idCategory, withCategory]);

    //   async function compartidasTrans(compartidas){
    //     // console.log(compartidas);
    //     try {
    //       const TOKEN = window.localStorage.getItem("loggedToken")
    //       const url = `${process.env.REACT_APP_BACKEND_URL}/api/share/cat/${compartidas[0].id}/`;
    //       const headers = {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Token ${JSON.parse(TOKEN)}`
    //       };
      
    //       fetch(url,{ headers, withCredentials:true })
    //       .then(response => response.json())
    //       // .then(data => console.log(data))
    //       .then(data => setCategory(data.filter(tran => tran.id === idCategory)) || "")
    //     }
    //      catch (error) {
    //       console.error(error);
    //     }
    //   }

      // NO
      async function HandlePendientes(id){
        // const PROFILE_ID = window.localStorage.getItem("profileId")  
        const TOKEN = window.localStorage.getItem("loggedToken")
        const formData = {transaction_id:id}
        console.log(formData);
        
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/transactions/pending/set/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${JSON.parse(TOKEN)}`
            },
            withCredentials:true,
            body: JSON.stringify(formData),
          });
          
          if (response.status === 200) {
          //   setLoader(false)
            // const data = await response.json();
            // console.log(response.status)
            window.location.reload()
            // setTransaccionesPendientes(data.sort((a, b) => new Date(a.date) - new Date(b.date)));
  
              // navigate("/home")
            } else {
              // setLoader(false)
              // setShowLoginError(true)
              console.error('Error submitting form');
            }
          } catch (error) {
            console.error('Error:', error);
          }
      }

      // async function deleteTransac(){
      //   const TOKEN = window.localStorage.getItem("loggedToken")
      //   try {
      //     fetch(`${process.env.REACT_APP_BACKEND_URL}/api/transactions/del/${identificador}/`, {
      //     method: 'DELETE',
      //     headers: {
      //       'Content-Type': 'application/json',
      //       'Authorization': `Token ${JSON.parse(TOKEN)}`
      //     },
      //     withCredentials:true,
      //   });
      // } catch (error) {
      //   console.log(error);
      // }
      // }

      // async function CompareCategory(){

      // }

      const FilteredProducts = productos?.filter(prod => prod.id === idProduct)
      const FilteredProductsDebt = gastos?.filter(prod => prod.product_id === idProduct)
      console.log(FilteredProductsDebt)
      
      

  return (
        <div className='table_row'>
            <p className='row_date'>{window.localStorage.getItem("editable") ? <img onClick={() => {setShowDate(!showDate); setCategoryId(identificador)}} src={edit_icon} alt='edit'/> : ""} {date}</p>
            {/* <p className='row_category3'>{prodName[0]?.name}</p> */}
            <p className='row_category3'>{withCategory ? `${FilteredProducts[0]?.name} (${walletName[0]?.name})` : `${walletName[0]?.name} (compartida)`}</p>
            <p className='row_category'>${profile_name.toLocaleString('es-MX')}</p>
            <p className='row_category2'>${Math.trunc(Number(FilteredProductsDebt[0]?.balance)).toLocaleString('es-MX')}</p>
            <p onClick={() => {console.log(transactions.filter((e) => {return e.id === identificador}))}} className='row_category'>${Math.trunc(Number(currAmount)).toLocaleString('es-MX')}</p>
            {pendiente
              ? <div className='row_btns'>
                  <button onClick={()=>{HandlePendientes(identificador)}} className='row_btn'>
                    <img src={check_pend} alt="delete transac" />
                  </button>
                </div>
              : window.localStorage.getItem("editable") ? <div className='row_btns'>
                  <button onClick={() => {setShowPassword(true);setMontoTrans(Number(currAmount));setShowModify(!showModify); setModifyId(identificador)}} className='row_btn'>
                    <img src={edit_row} alt="" />
                  </button>
                  <button onClick={()=>{setShowComments(!showComments); setCategoryId(identificador); setComments(transactions.filter((e) => {return e.id === identificador}))}} className='row_btn'>
                    {comments.length > 0 && comments[comments.length-1] !== ""
                      ? <div className='comment_noti'><span>{comments.length}</span></div>
                      : ""
                    }
                    <img src={comentarios} alt="comments transac" />
                  </button>
                  <button onClick={()=>{setShowConfirmation(!showConfirmation); setCategoryId(identificador); setApiUrl("transactions/")}} className='row_btn'>
                    <img src={cross_icon} alt="delete transac" />
                  </button>
                </div>
                : <div className="row_btns"></div>
            }
            {/* {loader ? <Loading /> : ""} */}
        </div>
  )
}

export default TablaTransProd
import edit_row from "../assets/edit_row.svg"
import delete_icon from "../assets/delete_icon.svg"
import more_less from "../assets/more_less.svg"
import "../styles/Categorias.scss"
// import { useNavigate } from "react-router-dom";
// import { useEffect, useState } from "react";
// import { getMonths } from "../services/getMonths";
// import { getWallets } from "../services/getWallets";
// import { getSharedWallets } from "../services/getSharedWallets";

function RowProducto({categorias,monthId,gastos,setPg, prodGroup, compartido, walletName,editable, icon, setIconProd, price, amount, setCantProd, setNameProd, setPriceProd, walletId,setWalletForTransac, dueñoId, setShowTransfer, setIfShared, compartida, setApiUrl, setModifyId, showModify, setShowModify, nombreCat, identificador,showAddTransaction,setShowAddTransaction, setCategoryId,showConfirmation,setShowConfirmation}) {

    // const navigate = useNavigate();

    // const today = new Date();
    // const dd = String(today.getDate()).padStart(2, '0');
    // const mm = String(today.getMonth() + 1).padStart(2, '0'); 
    // const yyyy = today.getFullYear();
  
    // const formattedDate = `${dd}/${mm}/${yyyy}`;
  
    // function HandleCheck(){
    //   const storageId = window.localStorage.getItem("categoryId")
    //   // setCategoryId(identificador)
    //   console.log(Number(storageId));
    //   if (!storageId) {
    //     window.localStorage.setItem("categoryId", identificador)
    //     navigate("/home")
    //   } else if(Number(storageId) !== identificador) {
    //     window.localStorage.removeItem("categoryId")
    //     window.localStorage.setItem("categoryId",identificador)
    //     navigate("/home")
    //   } else {
    //     window.localStorage.removeItem("categoryId")
    //     window.location.reload()
    //   }
    // }

    // const [compartido, setCompartido] = useState(false)
    // const [walletName, setWalletName] = useState([{name:""}])
    // const [gastos, setGastos] = useState([])
  
    // useEffect(() => {
    //     getMonths()
    //     .then(data => {
    //         if(data.months.length === 0){
    //             BringDatos(0)
    //         } else {
    //             BringDatos(Number(window.localStorage.getItem("monthId")))
    //         }
    //     })
    //     // .finally(        BringDatos())
    //     // eslint-disable-next-line
    //   },[])
  
  //     useEffect(() => {
  //       getWallets()
  //       .then(data => CombineCategories(data.filter(wall => wall.id === walletId)))
  // // eslint-disable-next-line
  //     },[])
  
    // async function BringDatos(id){
  
    //     const PROFILE_ID = window.localStorage.getItem("profileId")  
    //     const TOKEN = window.localStorage.getItem("loggedToken")
      
    //     const formData = {month_id:id , id:Number(PROFILE_ID)}
    //     // console.log(formData);
      
    //     if(id === 0){
    //         setGastos([])
    //     } else {
    //         try {
    //             const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/transactions/byprod/`, {
    //               method: 'POST',
    //               headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Token ${JSON.parse(TOKEN)}`
    //               },
    //               withCredentials:true,
    //               body: JSON.stringify(formData),
    //             });
                
    //             if (response.status === 200) {
    //             //   setLoader(false)
    //               const data = await response.json();
    //               // data.pop()
    //               // console.log(data);
    //               setGastos(data.filter(prod => prod.product === nombreCat));
        
    //                 // navigate("/home")
    //               } else {
    //                 // setLoader(false)
    //                 // setShowLoginError(true)
    //                 console.error('Error submitting form');
    //               }
    //             } catch (error) {
    //               console.error('Error:', error);
    //             }
    //           }
    //     }
  
      // async function CombineCategories(local){
  
      //     if(local.length === 0){
      //       setCompartido(true)
      //     }
      //       getSharedWallets()
      //       .then((data) => setWalletName(data.concat(local).filter(wall => wall.id === walletId)));
  
      // }

      function HandleCheck(){
        const storageId = window.localStorage.getItem("productId")
        // setProductId(identificador)
        console.log(Number(storageId));
        if (!storageId) {
          window.localStorage.setItem("productId", identificador)
          window.location.reload()
          // navigate("/home")
        } else if(Number(storageId) !== identificador) {
          window.localStorage.removeItem("productId")
          window.localStorage.setItem("productId",identificador)
          window.location.reload()
          // navigate("/home")
        } else {
          window.localStorage.removeItem("productId")
          window.location.reload()
        }
      }

      const filteredProducts = gastos.filter(prod => prod.product_id === identificador)
      

      
      
  
    return (
      <>
        <div>
            <div className='table_row'>
              <p className='row_date'>{amount !== 0 ? amount.slice(0, amount.length - 3): amount}</p>
              <p className='row_category'>{nombreCat} ({walletName[0]?.name}) {compartido ?"(compartida)":""}</p>
              {window.localStorage.getItem("compartida")
                ? <p className='row_abonado'>{identificador === 0 ? `$0` : filteredProducts.length === 0 ? "" : `$${filteredProducts[0].total_sum.toLocaleString('es-MX')}`}</p>
                : <p className='row_abonado'>{identificador === 0 ? `$0` :filteredProducts.length === 0 ? "" : `$${filteredProducts[0].total_sum.toLocaleString('es-MX')}`}</p>
              }
              <p className='row_deuda'>{identificador === 0 ? `$0` :filteredProducts.length === 0 ? "" : `$${(Number(filteredProducts[0].original_total)-Number(filteredProducts[0].total_sum)).toLocaleString('es-MX')}`}</p>
              <p className='row_total'>{identificador === 0 ? `$0` :filteredProducts.length === 0 ? "" : `$${Number(filteredProducts[0].original_total).toLocaleString('es-MX')}`}</p>
              <span></span>
              {window.localStorage.getItem("editable")
                ?
                <>
                  {identificador === 0 ? 
                  <div className="row_btns">
                    <button onClick={() => {setShowTransfer(true);console.log(prodGroup); setCategoryId(identificador); setPg(prodGroup)}} className='row_btn'>
                      <img style={{width:"11px"}} src={more_less} alt="delete transac" />
                    </button>
                  </div>
                  :
                  <div className='row_btns'>
                    <button className='transac_btn2' onClick={() => {setWalletForTransac(walletId);setShowAddTransaction(!showAddTransaction); setCategoryId(identificador); setIfShared(compartida)}}>
                      Realizar Registro
                    </button>
                    <button onClick={() => {setShowTransfer(true);console.log(prodGroup); setCategoryId(identificador); setPg(prodGroup)}} className='row_btn'>
                        <img style={{width:"11px"}} src={more_less} alt="delete transac" />
                    </button>
                    <button onClick={() => {setShowModify(!showModify); setModifyId(identificador); setCantProd(amount); setNameProd(nombreCat); setPriceProd(price);setIconProd(icon)}} className='row_btn'>
                      <img src={edit_row} alt="" />
                    </button>
                    <button onClick={() => {setShowConfirmation(!showConfirmation); setCategoryId(identificador); setApiUrl("")}} className='row_btn'>
                      <img src={delete_icon} alt="" />
                    </button>
                    <button style={Number(window.localStorage.getItem("productId")) === identificador ? {background:"#59D8E7", color:"#111820", border:"none"}:window.localStorage.getItem("light") ? {border:"1px solid #DBEBF8", color:"#000"} : {border:"1px solid #59D8E7", color:"#fff"}} className='row_btn' onClick={HandleCheck}>
                    Ver
                    </button>
                    {/* <input  style={{marginLeft:"10px"}} type="checkbox" /> */}
                  </div>
                  }
                </>
                : <div className="row_btns"></div>
              }
            </div>
        </div>
      </>
    )
}

export default RowProducto